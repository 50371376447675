

body.active-modal {
  overflow-y: hidden;
  overflow-x: hidden;  
}
.overlay-l {
  background: black;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  opacity: 0.7;
  z-index: 9999;
  overflow: hidden;
}

.modal-content-l {    
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /* top: 60%; */
  left: 50%;
  transform: translate(-50%, -8.5%);
  background: #ffff;
  max-width: 900px;
  min-width: 200px;
  color: #34657F;
  z-index: 999999999999;
  opacity: 1;
  padding: 0;
}
.modal-content-l > h2 { 
  background: #34657f; 
  padding: 10px;
  color: #000;
  margin: 0 ;
  height: 55px;
  width: 100%;
}
.term-content {
  height: 680px;
  font-size: 20px;
  overflow-y: auto;
  padding: 30px;  
}
.btn-agree {
  display: flex;
  justify-content: space-between !important;
  background-color: #ffffff;
  padding: 15px 20px;
  margin-bottom: 0;
  height: 75px;
  width: 100%;
  top: 0;
}

@media (max-height: 992px) {
  
}