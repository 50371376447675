.footer {
  background: #ffff;
  bottom: 0;
  border-top: 1px solid rgb(244, 244, 244);
  position: relative;
}

.footer-font-size {
  font-size: 13px;
}
ul.footer-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  display: flex;
  margin-bottom: 0;
}

.f-contain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-content {
  width: 100%;
}
.grid-foot-c {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
.feedback {
  width: 100px;
  max-height: 150px;
  margin-right: 5px;
}
.feedback:hover {
  cursor: pointer;
  width: 105px;
}

body.active-feedback {
  overflow-y: hidden;
}
.overlap {
  background: black;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  opacity: 0.8;
  z-index: 99999999999;
}
.overlap:hover{
  cursor: pointer;
}
.iframe {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffff;
  z-index: 999999999999;
  border-radius: 10px;
  width: 100%;
  max-height: 740px;
}

@media (width < 600px) {
  .feedback-container{
    max-width: 200px;
  }
  .feedback {
    border-radius: 20px;
    width: 80px;
    height: 100px;
    margin-top: 0px;
  }

}

@media (max-width: 500px) {
  .footer-font-size {
    font-size: 11px;
  }
}

@media (max-width: 350px) {
  .footer-font-size {
    font-size: 9px;
  }
}

.link {
  padding-bottom: 26px;
}

.alert-error {
  margin-bottom: calc(100vh - 195px) !important;
}

@media (min-width: 768px) {
  .alert-error {
    margin-bottom: calc(100vh - 192px) !important;
  }
}

@media (min-width: 992px) {
  .alert-error {
    margin-bottom: calc(100vh - 168px) !important;
  }
}