.pagination {
  background-color: #f7f7f7;
}
.page-item {
  color: #666666;
}
.page-link {
  background-color: #f7f7f7;
  border-color: #dddddd;
}
.page-item.disabled .page-link {
  opacity: 0.3;
  cursor: not-allowed;
}
.no-q {
  background: rgb(254, 254, 237);
  text-align: center;
  color: gray
}
