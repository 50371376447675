.invoice {
  display: flex;
  background: #34657f;
  padding: 8px;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem;
}
.invoice-link {
  background: #ffffff;
  padding: 0.2rem 1.3rem;
  border-radius: 1rem;
  font-size: 18px;
  color: #000000;
  margin-right: 0;
}
.invoice-link > img {
  margin-right: 0.2rem;
  width: 25px;
  height: 20px;
  padding-bottom: .2rem;
}
body.active-modal {
  overflow-y: hidden;
}
.overlay {
  background: black;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  opacity: 0.8;
  z-index: 99999999999;
}
.overlay:hover{
  cursor: pointer;
}
.modal-contents {
  position: relative;
  top: 172px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffff;
  padding: 60px;
  max-width: 600px;
  color: #34657F;
  font-size: 20px;
  z-index: 999999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.constents-div {
line-height: 26px;
max-width: 420px;
}
.onlinepay-btn {
  background-color: black;
  color: #f1f1f1;
  text-align: center;
  padding: 2px 30px;
  margin-top: 20px;
}
