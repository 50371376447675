@import url("https://fonts.googleapis.com/css?family=Overpass");
html,body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.full-height {
  background: #faf8f6;
  width: 100vw;
  min-height: 100vh;
  font-family: Overpass;
  font-style: normal;
  display: flex;
  flex-direction: column;
}

.drop-shadow {
  padding: 5%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
}

.hightower-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}

.synergy-button {
  padding: 10px 50px;
  border: 3px solid #1c78bd;
  border-radius: 0px;
  background: #1c78bd;
  color: #ffffff;
}

.tp-button {
  padding: 10px 50px;
  border: 3px solid #337ab7;
  background: #337ab7;
  color: #ffffff;
}

.aline-background {
  background-color: #eee;
}

.aline-button {
  padding: 10px 50px;
  border: 3px solid #0731f7;
  border-radius: 0px;
  background: #0731f7;
  color: #ffffff;
}

.falcon-background {
  background-color: #e2e1d3;
}

.falcon-button {
  padding: 10px 50px;
  border: 3px solid #1d4460;
  border-radius: 0px;
  background: #1d4460;
  color: #ffffff;
}

.morgia-button {
  padding: 10px 50px;
  border: 3px solid #1c78bd;
  border-radius: 0px;
  background: #1c78bd;
  color: #ffffff;
}

.harvey-button {
  padding: 10px 50px;
  border: 3px solid #1c78bd;
  border-radius: 0px;
  background: #009d4f;
  color: #ffffff;
}

.tbg-background {
  background-image: url(./images/tbg-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.tbg-button {
  padding: 10px 50px;
  border: 3px solid #000000;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}

.fortress-background {
  background-color: #eee;
}

.fortress-button {
  padding: 10px 50px;
  border: 3px solid #1d1f2c;
  border-radius: 0px;
  background: #002856;
  color: #ffffff;
}

.cognetic-background {
  background-color: #eee;
}

.cognetic-button {
  padding: 10px 50px;
  border: 3px solid #244fa5;
  border-radius: 0px;
  background: #244fa5;
  color: #ffffff;
}

.hma-background {
  background-color: #eee;
}

.hma-button {
  padding: 10px 50px;
  border: 3px solid #105938;
  border-radius: 0px;
  background: #105938;
  color: #ffffff;
}

.somerset-background {
  background-color: #a3b2a8;
}

.somerset-button {
  padding: 10px 50px;
  border: 3px solid #a3b2a8;
  border-radius: 0px;
  background: #a3b2a8;
  color: #ffffff;
}

.lexington-background {
  background-color: #175878;
}

.lexington-button {
  padding: 10px 50px;
  border: 3px solid #175878;
  border-radius: 0px;
  background: #175878;
  color: #ffffff;
}

.irongate-background {
  background-color: #ccbca3;
}

.irongate-button {
  padding: 10px 50px;
  border: 3px solid #ccbca3;
  border-radius: 0px;
  background: #ccbca3;
  color: #ffffff;
}

.dsg-background {
  background-image: url(./images/dsg-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.dsg-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #4b4f54;
  color: #ffffff;
}

.alert-background {
  background-color: #fff3cd;
}

.sign-out {
  color: #000000 !important;
}

.schultz-button {
  padding: 10px 50px;
  border: 3px solid #3d4934;
  border-radius: 0px;
  background: #3d4934;
  color: #ffffff;
}

.spinner-background {
  background-color: #fff;
}

.stearns-button {
  padding: 10px 50px;
  border: 3px solid #104b7d;
  border-radius: 0px;
  background: #104b7d;
  color: #ffffff;
}

.lourd-button {
  padding: 10px 50px;
  border: 3px solid #000;
  border-radius: 0px;
  background: #000;
  color: #ffffff;
}

.isg-button {
  padding: 10px 50px;
  border: 3px solid #000;
  border-radius: 0px;
  background: #000;
  color: #ffffff;
}

.argent-button {
  padding: 10px 50px;
  border: 3px solid #303c52;
  border-radius: 0px;
  background: #303c52;
  color: #ffffff;
}
.teaktree-button {
  padding: 10px 50px;
  border: 3px solid #8d8079;
  border-radius: 0px;
  background: #8d8079;
  color: #ffffff;
}
.bluerock-button {
  padding: 10px 50px;
  border: 3px solid #1f2d5e;
  border-radius: 0px;
  background: #1f2d5e;
  color: #ffffff;
}
.andriolegroup-button {
  padding: 10px 50px;
  border: 3px solid #3f758f;
  border-radius: 0px;
  background: #3f758f;
  color: #ffffff;
}
.greensquare-button {
  padding: 10px 50px;
  border: 3px solid #39803e;
  border-radius: 0px;
  background: #39803e;
  color: #ffffff;
}
.rikoon-button {
  padding: 10px 50px;
  border: 3px solid #40989e;
  border-radius: 0px;
  background: #40989e;
  color: #ffffff;
}
.clearperspective-button {
  padding: 10px 50px;
  border: 3px solid #174a7c;
  border-radius: 0px;
  background: #174a7c;
  color: #ffffff;
}
.fairport-button {
  padding: 10px 50px;
  border: 3px solid #777777;
  border-radius: 0px;
  background: #777777;
  color: #ffffff;
}
.alexcapital-button {
  padding: 10px 50px;
  border: 3px solid #18415d;
  border-radius: 0px;
  background: #18415d;
  color: #ffffff;
}
.twickenham-button {
  padding: 10px 50px;
  border: 3px solid #34657f;
  background: #34657f;
  color: #ffffff;
}
.ria-button {
  padding: 10px 50px;
  border: 3px solid #30a471;
  border-radius: 0px;
  background: #30a471;
  color: #ffffff;
}
.rdm-button {
  padding: 10px 50px;
  border: 3px solid #d68624;
  border-radius: 0px;
  background: #d68624;
  color: #ffffff;
}
.great-lakes-button {
  padding: 10px 50px;
  border: 3px solid #d68624;
  border-radius: 0px;
  background: #d68624;
  color: #ffffff;
}

.bethesda-button {
  padding: 10px 50px;
  border: 3px solid #d68624;
  border-radius: 0px;
  background: #d68624;
  color: #ffffff;
}
.landsbergbennett-button {
  padding: 10px 50px;
  border: 3px solid #1a2857;
  border-radius: 0px;
  background: #1a2857;
  color: #ffffff;
}
.httcwealthpartners-button{
  padding: 10px 50px;
  border: 3px solid  #FF6E15;
  border-radius: 0px;
  background:  #FF6E15;
}
.sixmeridian-button{
  padding: 10px 50px;
  border: 3px solid  #514747;
  border-radius: 0px;
  background:  #514747;
  color: #ffffff;
}
.natoma-button{
  padding: 10px 50px;
  border: 3px solid  #294463;
  border-radius: 0px;
  background:  #294463;
  color: #ffffff;
}
.lasvegas-button{
  padding: 10px 50px;
  border: 3px solid  #4B4F54;
  border-radius: 0px;
  background:  #4B4F54;
  color: #ffffff;
}
.thedilligbowen-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}
.sillerandcohen-button {
  padding: 10px 50px;
  border: 3px solid  #545759;
  border-radius: 0px;
  background: #545759;
  color: #ffffff;
}
.vwg-button {
  padding: 10px 50px;
  border: 3px solid #FF6A14;
  border-radius: 0px;
  background: #FF6A14;
  color: #ffffff;
}
.lck-button {
  padding: 10px 50px;
  border: 3px solid #FF6A14;
  border-radius: 0px;
  background: #FF6A14;
  color: #ffffff;
}
.strategicpartners-button {
    padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}
.mtwh-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}
.rand-button {
  padding: 10px 50px;
  border: 3px solid #34657F;
  border-radius: 0px;
  background: #34657F;
}
.claritypoint-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}
.tbgtest-button {
  padding: 10px 50px;
  border: 3px solid #000000;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}
.tbgtest-background {
  background-image: url(./images/tbg-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.bickling-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}
.austin-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}
.presidio-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #4b4f54;
  color: #ffffff;
}
.feinberg-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #4b4f54;
  color: #ffffff;
}
.highland-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #4b4f54;
  color: #ffffff;
}
.strata-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #4b4f54;
  color: #ffffff;
}
.xception-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #4b4f54;
  color: #ffffff;
}