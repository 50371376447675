.terms-of-use {
  font-family: Overpass;
  font-style: normal;
  font-weight: 700;
  min-height: 85px;
  padding-top: 25px;
  margin-left: 40px;
  color: #3498db;
}
.ht-gray-stripe-fill {
  background-repeat: repeat;

  background-position-x: center;

  background-image: url("https://images.ctfassets.net/nac4rsvtoh64/3FBGhXZvG3TDagKv1XlnP/13e23e5dc519637caacde721bcbbd732/ht-gray-stripe-fill.png?fm=webp");
}
.min-height-50 {
  min-height: 50px;
}
.terms-head {
  background: #34657f; 
  padding: 10px;
  color: #000;
  margin: 0 ;
  height: 55px;
  width: 100%;
}
.legal-content-container{
  background:#ffffff;
}
.terms-body {
  padding: 0 30px 20px 30px;
  font-size: 20px;
}
.legal-footer-container {
  bottom: 0;
  width: 100%;
  margin-top: 10px;
}