.layout {
  width: 100%;
  height: 100%;
}

.header {
  height: 100px;
  background: #ffffff;
}

.header row {
  height: 100px;
}

.logo img {
  width: auto;
  height: 85px;
}

@media (max-width: 500px) {
  .logo img {
    /* width: auto;
    height: 34px; */
    object-fit: contain;
  }
}

.userInfo button {
  padding-right: 0px;
}

.signOut {
  float: right;
}

.card-horizontal {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 20px;
  height: auto!important;
  line-height: auto!important;
}

.main {
  margin: 3.5rem 6.8125rem 0 6.8125rem;
}

.card {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.card-body {
  padding: 1.5rem !important;
}

.card-content {
  column-gap: 1.5rem;
}

.card-content a {
  color: black;
  text-decoration: none;
}

.card-content a:hover {
  color: black;
  text-decoration: none;
}

.advisor-content {
  padding: 15px;
}

.advisorName {
  font-size: 32px;
  line-height: 36px;
  color: #000000;
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

.advisor-address {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #0073b0;
  margin-top: 1.5rem;
  white-space: nowrap;
}

.advisor-address img {
  margin-right: 0.5rem;
}

.addressIcon {
  width: 15px;
  height: 20px;
}

.emailIcon {
  width: 20px;
  height: 15px;
  margin-top: 5px;
}

.email-text {
  word-break: break-word;
}

.contact-icon {
  width: 20px;
  height: auto;
}

.contact {
  color: #0073b0;
  white-space: break-spaces;
}

.phoneIcon {
  width: 20px;
  height: 20px;
}

.schedule {
  width: 100%;
  height: 48px;
  background: #34657f;
  border-radius: 51px;
  align-items: center;
  justify-content: center;
  padding-left: 1.5rem;
}

.schedule-wide {
  width: 142px;
  height: 48px;
  background: #34657f;
  border-radius: 51px;
  align-items: center;
  padding-left: 1.5rem;
}

.schedule-wide span {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

.schedule-wide img {
  width: 17.5px;
  height: 20px;
  margin: 0 0.5rem 0.313rem 0;
}

.s-schedule {
  height: 40px;
}

.schedule img {
  width: 17.5px;
  height: 20px;
  margin: 0 0.5rem 0.313rem 0;
}

.schedule span {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

.card {
  box-shadow: none;
}

.vendor {
  height: 100%;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid #eae9e8;
}

.vendor:hover {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.line {
  width: 316px;
  height: 1px;
  background: #bfb8af;
}

.card-text {
  padding-top: 1.5rem;
}

.text-title {
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 1rem;
}

.col-margin {
  padding-right: 0 !important;
}

.userInfo {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: black;
  margin-top: 1.5rem;
}

.userInfo-row {
  margin-bottom: 0.625rem;
}

.userInfo img {
  margin-right: 0.5rem;
}

.card-gutter {
  padding: 0 0 1.5rem 0 !important;
  margin: 0 !important;
}

.ellipse {
  border-radius: 50%;
  height: 146px;
  width: 146px;
}

.footer {
  min-height: 100px;
}

.decoration-none {
  text-decoration: none !important;
}
.c-pointer{	
  cursor: pointer;	
}
.card-padding {
  padding-bottom: 30px;
}

.account-link {
  border: 2px solid #34657f;
  border-radius: 51px;
  color: #34657f;
}

.portal-logo-container {
  height: 65px;
  display: flex;
  align-items: center;
}

.portal-logo {
  max-height: 65px;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
}

@-moz-document url-prefix() {
  .portal-logo {
    width: 292.5px;
  }
}

.portal-logo-width {
  width: 100%;
}

.btn-sign-out {
  padding: 0 !important;
}

.contact-user {
  color: #000000;
}

.logo-wide {
  height: auto;
  width: 250px;
  object-fit: cover;
}

.sign-out {
  color: #000000 !important;
}
.menu-calc-height {
  padding-bottom:90px
}
.menu-footer-container {
  bottom: 0;
  margin-top:10px;
}
.min-height-card {
  min-height: 320px;
}
.div-x{
 margin: -5px 0;
}
.div-x >p  {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}