.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 400px);
  grid-gap: 10px;
  margin: 0 auto;
  padding: 30px;
}
.grid-item {
  height: 460px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding-top: 10px;
}
.first-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(hsl(200, 40%, 94%), white);
}
.profile {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}
.profile-image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.profile h2 {
  font-size: 1.2em;
  margin: 10px;
  font-weight: 800;
  letter-spacing: -0.5px;
}
.profile h4 {
  font-size: 1em;
  margin: 0;
  margin-bottom: 20px;
  color: #373636;
  letter-spacing: -0.5px;
}
.profile a {
  background: hsl(209, 83%, 47%);
  color: #fff;
  text-decoration: none;
  padding: 10px 10px;
  border-radius: 5px;
  margin-top: 20px;
  letter-spacing: -1px;
}
.call {
  background: rgb(233, 232, 232);
  width: 30px;
  height: 30px;
  font-size: 15px;
  color: #5c5c5c;
  text-align: center;
  border-radius: 50%;
  padding: 5px;
}
.buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
  font-size: 15px;
  letter-spacing: -1px;
}
.buttons button {
  padding: 5px;
  background: #fff;
  color: #7a7979;
  border: none;
  cursor: pointer;
}
.buttons button:first-child {
  margin-right: auto;
}
.buttons button:last-child {
  margin-left: auto;
}
.remaining-width {
  grid-column: 2 / span 2;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.remaining-width > h2 {
  font-size: 20px;
  height: 20%;
  font-weight: bold;
  align-self: start;
}
.remaining-width > div {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 2px solid rgb(151, 150, 150);
  padding-top: 20px;
  padding-bottom: 0;
  height: 80%;
}
.link {
  text-decoration: none;
  letter-spacing: -1px;
  float: left;
  width: 100%;
  padding: 20px 15px;
  padding-bottom: 0px;
  font-weight: 800;
  font-size: 16px;
}
.todo-item {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 10px;
}
.todo-task {
  font-weight: 600;
  letter-spacing: -1px;
  font-size: 14px;
  padding: 5px;
  background: #f1eded;
  width: 80px;
}
.todo-head {
  letter-spacing: -1px;
  font-weight: 800;
  font-size: 18px;
}
.todo-body {
  letter-spacing: -1px;
  font-size: 16px;
  font-weight: 100;
  color: #7a7979;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #3498db;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
  margin-top: -40px;
  position: relative;
}
.completed {
  background-color: #3498db;
  border-color: #3498db;
}
.border-completed {
    border-top: #3498db 2px solid;
}
.checkmark {
  font-size: 18px;
  margin-right: 3px;
}
.todo-text {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
.todo-item > * {
  flex-direction: row;
}
.item-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  letter-spacing: -1px;
}
.item-3 > * {
  width: 100%;
  padding: 20px;
  padding-bottom: 0;
}
.item-3 >div > h2 {
  font-size: 20px;
  font-weight: bold;
}
.item-3 >div > ul {
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: 100;
  color: #686767;
}
.item-3  > a {
  font-size: 18px;
  letter-spacing: -1px;
  font-weight: bold;
  margin-bottom: 10px;
}
.gain-loss {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: start;
  align-items: start;
  padding: 20px;
}
.gain-loss > h2 {
  font-size: 30px;
  font-weight: bold;
}
.gain-loss > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: -10px;
  padding: 0;
  height: 35px;
}

.gain-loss > div > h2 {
  font-size: 20px;
  font-weight: bold;
}
.gain-loss > div > p {
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: 100;
  color: #7a7979;
}
.gain-loss > div > span {
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: bold;
  color: #7a7979;
}
.gl-link {
  text-decoration: none;
  letter-spacing: -1px;
  float: left;
  width: 100%;
  padding: 45px 0;
  padding-bottom: 0px;
  font-weight: 800;
  font-size: 16px;
  bottom: 0;
}
/* .quick-link {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 20px;
}
.quick-link > h2 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 25px;
}
.quick-link > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-bottom: -10px;
  padding: 0;
  height: 35px;
}
.quick-link > div > h2 {
  font-size: 20px;
  font-weight: bold;
}
.q-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.q-content > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
.q-content > div > h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: -6px;
  margin-top: 10px;
}
.q-content > div > a {
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: 100;
} */
.recent {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 20px;
}
.recent > h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
}
.styled-table {
  width: 100%;
  border-collapse: collapse;
}
.styled-table th {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.styled-row {
  height: 60px;
  border-bottom: 1px solid #ccc;
}
.styled-row td {
  padding: 10px;
}
.recent >a {
    font-size: 16px;
    letter-spacing: -1px;
    font-weight: bold;
    justify-self: start;
    margin-top: 130px;
}
.news {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 20px;
}
.news > h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
}
.news > div {
padding: 0 10px ;
}
.news > div > h2 {
  font-size: 16px;
  font-weight: 600;
}
.news > div > p {
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: 100;
  color: #7a7979;
}
.news > div > a {
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: bold;
}
.popup {
   background-color: #fff;
  box-shadow: 0 0 5px rgba(10, 10, 10, 0.2);
  padding: 10px;
  position: absolute;
  top: 100%; 
  left: -100px; 
  z-index: 1;
  width: 30vw;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup p {
  margin: 0;
  padding: 10px;
}
.popup button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
}
.donat-percent{
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding: 20px;

}
.donat-percent > h2 {
  font-size: 20px;
  font-weight: bold;
  float: left;
  width: 100%;
  margin-bottom: -1px;
}
.donat-percent > p {
  font-size: 14px;
  letter-spacing: -1px;
  font-weight: 100;
  color: #7a7979;
  float: left;
  width: 100%;
  margin-bottom: 25px;
}
.donut-chart {
  width: 100%;
  height: 250px;
}
.donat-percent > a {
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: bold;
  float: left;
  width: 100%;
  margin-top: 60px;
  align-items: end;
  justify-self: baseline;
}
