.accordion-button {
  border: 0;
  background: none;
}
.accordion-heading {
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  position: relative;
}
.card-horizontal {
  margin-bottom: 10px;
  padding: 0 0 0 20px;
  height: 50px;
  line-height: 50px;
}
.accordion-item {
  margin-bottom: 10px;
  padding: 0;
  border-top: 1px solid #dee2e6 !important;
}
.accordion-button:focus {
  border: 0;
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  background-color: #ffffff;
  color: #212529;
}
.icon {
  margin-top: -4px;
  font-size: 24px;
  margin-right: 10px;
}
.filter-icon {
  margin-top: -4px;
  font-size: 30px;
  margin-right: 10px;
}
.duty-icon {
  color: #fa9600;
  position: absolute;
  right: 15px;
  top: 13px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Overpass !important;
}
.grid-icons {
  font-size: 22px;
}
.filter-heading {
  display: flex;
  border-bottom: 1px solid #b1b1b1 !important;
}
.filter-container {
  display: flex;
  /* position: absolute; */
  background-color: #f8f8f8;
  font-size: 15px;
  /* width: 92%; */
  padding: 16px;
  top: 32px;
  justify-content: space-around;
}
.filter-text {
  border-bottom: 2px solid #e7e7e7;
  /* font-size: 28px; */
  margin-bottom: 20px;
  margin-right: 200px;
  float: left;
  width: 30%;
  margin-left: 750px;
}
.filterTable {
  width: 30%;
}
.form-select {
  padding: 5px 35px 0 10px;
  font-size: 15px;
  border: 0;
  border-radius: 0;
  line-height: 17px;
  margin-left: 8px;
}
.form-select:focus {
  border: 0 !important;
  outline: 0;
  box-shadow: none !important;
}
.table td,
.table th {
  border: 0 !important;
}
.table > :not(caption) > * > * {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(250 248 246) !important;
  box-shadow: none;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  box-shadow: none;
}
#filter-container input:focus {
  border-bottom-color: #4f41e9;
}

.file-icon {
  font-size: 25px;
  margin-left: 40;
}
.eye-icon {
  font-size: 25px;
  padding: 2px 4px;

  margin-left: 200px;
  align-items: center;
}
.download-icon {
  padding: 10px 20;
  margin-left: 20px;
  margin-bottom: 8px;
  margin-right: 20px;
  align-items: center;
}

.table {
  font-size: 15px;
  width: 100%;
  margin-right: 40px;
}

.table-fixed tr,
.table-fixed td,
.table-fixed th {
  /* padding: auto; */
  font-size: 80px;
  margin-left: 40px;
  padding: 10px 30px;
  margin-right: 70px;
}
.table-fixed td {
  padding: 40px 80px;
}

.table-header,
tr,
td,
th {
  /* padding: auto; */
  font-size: 15px;
  padding: 20px 40px;
  margin-right: 20px;
}
.table-Header {
  margin-left: 50px;
}

.table-body,
tr,
th,
td {
  margin-right: 40px;
  font-size: 15px;
  padding: 20px 40px;
}
.page-item:first-child .page-link {
  border-radius: none;
}
 .pagination {
  margin: 0 auto;
}
/*.pagination div {
  margin: 5px !important;
}
.pagination div div {
  border-radius: 20% !important;
} */

.page-item .page-link {
  background-color: rgb(221, 217, 217) !important;
  color: black;
  border-radius: 5px;
  font-weight: bold;
}

.page-item.disabled .page-link {
  background-color: rgb(221, 217, 217) !important;
  color: black !important;
  border-radius: 5px;
  font-weight: bold;
}

.page-item.active .page-link {
  border-radius: 5px;
  background-color: #0275d8 !important;
  font-weight: bold;
}

.page-link:hover {
  color: #fff !important;
}

#files-text {
  margin-left: 530px;
  display: inline;
  margin-bottom: 100px;
  font-size: 20px;
}

.files-per-text {
  font-size: 20px;
  margin-bottom: 70px;
  padding: 10px 20px;
  margin-bottom: 50px;
}
.horizontal-line {
  margin-left: 640px;
  padding: 10px 20px;

  width: 20%;
  margin-bottom: 30;
}

hr-line {
  display: flex;
  margin-bottom: 20px;
  margin-bottom: 30;
  height: 4px;
  background-color: gray;
  border-width: "0";
  color: gray;
  font-size: 20px;
}

.vault-img {
  display: flex;
  margin-top: 90px;
  margin-bottom: 25px;

  justify-content: space-between;
  /* margin-left: 320px; */
}

#button {
  /* border: 2px solid; */
  padding: 8px 32px;
}
#button {
  background-color: #e7e7e7;
  color: black;
  border: 2px solid #e7e7e7;
}
#button:hover {
  background-color: #0275d8;

  color: white;
}

.download-file-icon {
  font-size: 28px;
  margin-top: 10px;
  color: black;
}
.file-download-text {
  font-size: 27px;
  margin-top: 30px;
  color: black;
  margin-left: 0px;
  align-items: center;
  display: flex;
}
.modal-button {
  margin-left: 10rem;
  margin-bottom: 40px;
}
.modal-pop {
  width: 40%;
  size: 30px;
}

.pdf-viewer-container {
  background-color: #fff;
  left: 0;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: flex;
}
.pdf-viewer-preview {
  width: 100%;
  padding-top: 30px;
  text-align: center;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
}
.button-align {
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.download-button {
  border: none;
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 4px;
}
.download-button:hover {
  background-color: #41abe9;
  color: #fff;
}
.iframe-container {
  height: 90%;
}
.pdfViwer {
  cursor: pointer;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}
a.accordion {
  color: #212529;
}
a.accordion:hover {
  color: #212529;
  text-decoration: none;
}
.page-link:focus{
  box-shadow: none!important;
}
.page-link:hover{
  color:black!important;
}
.accordion-footer-container {
  bottom: 0;
  width: 100%;
  margin-top: 10px;
}