.carousel-indicators [data-bs-target] {
  height: 5px;
  background-color: rgb(189, 209, 209);
}
.carousel-indicators {
  margin-bottom: -25px;
}
.carousel-indicators .active {
  background-color: rgb(146, 146, 147);
}
.h-quickilink {
  letter-spacing: -0.4px;
  font-weight: bold;
  font-size: 25px;
  color: rgb(96, 96, 97);
}
.m-Carousel {
  padding-left: 20px;
  margin: 0 -25px;
  height: 140px;
}

.q-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.img-quicklink {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.q-content > div > h3 {
  font-size: 18px;
  font-weight: bold;
}
.q-content > div > a {
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: 100;
}